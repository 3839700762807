@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";

.home-container {
  padding-top: 80px;
}
.custom-eventlist {
  padding-top: 80px;
}
.card {
  margin: 15px;
  border: none;
  .card-body {
    border: none;
    color: #ffffff;
    padding: 1.75rem 1.5625rem;
    background: #164a95;
  }
}

.container-scroller {
  background-color: #0c2448;
}

.form-control {
  border: 1px solid #2c2e33;
  height: calc(2.25rem + 2px);
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0.625rem 0.6875rem;
  background-color: #0c2447;
  border-radius: 2px;
  color: #ffffff;
}
h4 {
  padding: 10px;
  margin: 10px;
}

.table {
  color: #ffffff;
}

.event-custom-select {
  .css-g1d714-ValueContainer {
    padding-top: 10px !important;
  }
  .css-1hb7zxy-IndicatorsContainer {
    position: absolute !important;
    right: 0;
    top: 0;
  }
  .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

a {
  text-decoration: none !important;
}

.logo-img {
  height: 40px;
  width: 40px;
  border-radius: 50px;
}
.account_mob_ {
  span {
    font-size: 8px !important;
  }
}

@media only screen and (max-width: 600px) {
  .user-detail-res {
    .d-flex {
      display: unset !important;
      .heading_ {
        font-weight: 800 !important;
      }
      h4 {
        display: block !important;
        width: 100% !important;
      }
    }
  }
}

.ref-btn {
  color: #fff;
  background-color: #0b6efd;
  border: none;
  padding: 12px 25px;
  border-radius: 36px;
  margin: 15px 0 15px 0;
}
.ref-btn-success {
  color: #fff !important;
  background-color: #077f03 !important;
  padding: 12px 15px;
  border-radius: 35px;
  margin-left: 15px;
}
